@import 'abstracts';

.spinner {
  position: absolute;
  background: $white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;

  @include flexCentering();
}
