@import 'abstracts';

.cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn,
.cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn:hover {
  height: $cap-input-height;
}

body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button,
body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn:hover .p-datepicker-trigger.p-button {
  left: 0.8rem;
  opacity: 0.8;

  span.p-button-icon {
    font-size: $cap-calendar-input-hover-trigger-button-icon-left-font-size;
  }
}

body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn input:enabled:hover:not(.p-state-error),
body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn input:enabled:focus:not(.p-state-error),
body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn input,
body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn:hover input:enabled:hover:not(.p-state-error),
body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn:hover input:enabled:focus:not(.p-state-error),
body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn:hover input {
  left: 2rem;
  width: calc(100% - 2rem);
  font-family: $font-regular;
}

body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn {
  input.p-inputtext {
    cursor: pointer;
  }

  &:after {
    pointer-events: none;
    content: '\f107';
    font-family: 'Font Awesome 5 Pro';
    cursor: pointer;
    position: absolute;
    right: 0.7rem;
    top: 0.7rem;
    z-index: 1;
    color: $tuatara;
  }
}

cap-calendar td.p-datepicker-weeknumber {
  border-right: 1px solid $gray;
  color: $gray;
}

body .p-datepicker-inline {
  display: inline-table;
  z-index: 1;
}
