@import 'abstracts';

$box-shadow:  0 3px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 1px 0 rgba(0, 0, 0, 0.06);

body .p-inputswitch {
  width: 2.813rem;
  height: 1.563rem;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}

div.p-inputswitch span.p-inputswitch-slider:before {
  height: 1.313rem;
  width: 1.313rem;
  top: 0.125rem;
  left: 0.125rem;
  margin-top: 0;
  bottom: 0.063rem;
  box-shadow: $box-shadow;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
  background: #82ad11;
}

cap-input-switch .form-element {
  display: flex;
  align-items: center;
}
