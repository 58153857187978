@import 'abstracts';

.main-content {
  background: $body-color;
  padding: 9.2rem 5rem 0;
}

body .content-wrapper .content-left div.logo {
  height: 15rem;
}

body .login-wrapper {
  @media (max-height: 730px) {
    top: 45%;
  }

  label {
    line-height: 1.313rem;
  }
}

.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 1.75rem;
    font-weight: bold;

    i {
      font-size: 1.625rem;
      color: $primary;
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    height: auto;
    padding: 1.25rem;

    h2 {
      margin-bottom: 1rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .main-content {
    background: $white;
    padding: 9.2rem 2.5rem 5rem;
  }
}

@include media-breakpoint-down(sm) {
  cap-login .login-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 650px;
    top: 0;
    right: 0;
    left: 0;
  }

  .flex-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    cap-button {
      width: 100%;

      &:first-of-type {
        margin-right: 1rem;
      }

      .p-button-label {
        font-family: $font-bold;
        font-size: 1.6rem;
      }

      .p-button {
        height: 4rem;
      }
    }
  }
}