@import 'abstracts';

.form-element label {
  margin-left: 0;
  margin-bottom: 0.5rem;
  font-family: $font-medium;
  font-size: 1rem;
  color: $mine-shaft;
  line-height: 1.625rem;
}

@include media-breakpoint-down(md) {
  .form-element label {
    margin-bottom: 1rem;
    font-size: 1.6rem;
    line-height: normal;
  }
}
