@import 'abstracts';

body .p-dialog {
  background: white;
  border-radius: 5px;

  .p-dialog-header {
    background: $white;
    margin: 0 2rem;
    padding: 2rem 0;
    border-bottom: 1px solid $light-gray;

    &:after {
      content: none;
    }

    .p-dialog-title {
      font-weight: bold;
      font-size: 22px;
      color: $primary;
    }

    .p-dialog-header-icons .p-dialog-header-close-icon {
      color: $light-gray;
    }
  }

  .p-dialog-content {
    border: none;
    border-radius: 0 0 5px 5px;
    padding: 1rem 2rem 2rem;
  }

  &.confirm-dialog {
    width: 44.313rem;
    z-index: 10000;

    .p-dialog-content {
      max-height: 31.25rem;
      overflow: auto;
      padding: 1.625rem 2.063rem;
    }
  }
}
