@import 'abstracts';

.cap-textarea:disabled {
  border-color: $gray;
}

.cap-textarea::placeholder {
  color: $light-gray;
}

.cap-textarea {
  border: 1px solid $gray;
  color: $mid-gray;
  padding: 0.313rem $small-gutter-width;
  font-size: 1rem;
  font-family: $font-regular;
}

.pseudo-textarea {
  background: $body-color;
  border-radius: 3px;
  border: 1px solid $gray;
  min-height: 5.625rem;
  padding: 0.75rem 0.938rem;
  color: $mid-gray;
  font-family: $font-regular;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.25rem;
  overflow-wrap: break-word;
}
