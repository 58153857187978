@import 'abstracts';

body .p-paginator .p-dropdown {
  border: 1px solid $gray;
  height: 2.375rem;
}

.cap-dropdown.p-dropdown .p-dropdown-trigger,
.cap-dropdown.p-dropdown .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled):hover .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled):hover .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled).p-state-focus .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled).p-state-focus .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled):hover .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled):hover .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled).p-state-focus .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled).p-state-focus .p-multiselect-trigger,
.cap-multi-select.p-multiselect .p-dropdown-trigger,
.cap-multi-select.p-multiselect .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled):hover .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled):hover .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled).p-state-focus .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled).p-state-focus .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled):hover .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled):hover .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled).p-state-focus .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled).p-state-focus .p-multiselect-trigger {
  border-left: none;
  font-size: 0.7rem;
  margin-right: 0.5rem;
}

.cap-dropdown.p-dropdown .p-dropdown-label,
.cap-dropdown.p-dropdown .p-multiselect-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled):hover .p-dropdown-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled):hover .p-multiselect-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled).p-state-focus .p-dropdown-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled).p-state-focus .p-multiselect-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled):hover .p-dropdown-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled):hover .p-multiselect-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled).p-state-focus .p-dropdown-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled).p-state-focus .p-multiselect-label,
.cap-multi-select.p-multiselect .p-dropdown-label,
.cap-multi-select.p-multiselect .p-multiselect-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled):hover .p-dropdown-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled):hover .p-multiselect-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled).p-state-focus .p-dropdown-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled).p-state-focus .p-multiselect-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled):hover .p-dropdown-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled):hover .p-multiselect-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled).p-state-focus .p-dropdown-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled).p-state-focus .p-multiselect-label {
  padding-left: 0.8rem;
  color: $tuatara;
  font-family: $font-regular;
  font-size: 1rem;
  height: calc(31px - 2px);
  padding-top: 0.6rem;
}

.p-dropdown-panel .p-dropdown-item {
  font-size: 1rem;
  font-family: $font-regular;
}

.cap-dropdown.p-dropdown,
.cap-dropdown.p-dropdown .p-dropdown-label {
  border-radius: 3px;
}

.cap-dropdown.p-dropdown,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus,
.cap-multi-select.p-multiselect,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus {
  height: 2.375rem;
}

.cap-dropdown.p-dropdown .p-dropdown-label,
.cap-dropdown.p-dropdown .p-multiselect-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-dropdown-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-multiselect-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-dropdown-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-multiselect-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-dropdown-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-multiselect-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-dropdown-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-multiselect-label,
.cap-multi-select.p-multiselect .p-dropdown-label,
.cap-multi-select.p-multiselect .p-multiselect-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-dropdown-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-multiselect-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-dropdown-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-multiselect-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-dropdown-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-multiselect-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-dropdown-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-multiselect-label {
  height: calc(31px - 2px);
}

.cap-dropdown.p-dropdown .p-dropdown-label,
.cap-dropdown.p-dropdown .p-multiselect-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-dropdown-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-multiselect-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-dropdown-label,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-multiselect-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-dropdown-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-multiselect-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-dropdown-label,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-multiselect-label,
.cap-multi-select.p-multiselect .p-dropdown-label,
.cap-multi-select.p-multiselect .p-multiselect-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-dropdown-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-multiselect-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-dropdown-label,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-multiselect-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-dropdown-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-multiselect-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-dropdown-label,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-multiselect-label {
  height: 28px;
}

@include media-breakpoint-down(md) {
  .cap-dropdown.p-dropdown,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus,
  .cap-multi-select.p-multiselect,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus {
    height: auto;
  }

  .cap-dropdown.p-dropdown .p-dropdown-label,
  .cap-dropdown.p-dropdown .p-multiselect-label,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled):hover .p-dropdown-label,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled):hover .p-multiselect-label,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled).p-state-focus .p-dropdown-label,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-state-disabled).p-state-focus .p-multiselect-label,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled):hover .p-dropdown-label,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled):hover .p-multiselect-label,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled).p-state-focus .p-dropdown-label,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-state-disabled).p-state-focus .p-multiselect-label,
  .cap-multi-select.p-multiselect .p-dropdown-label,
  .cap-multi-select.p-multiselect .p-multiselect-label,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled):hover .p-dropdown-label,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled):hover .p-multiselect-label,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled).p-state-focus .p-dropdown-label,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-state-disabled).p-state-focus .p-multiselect-label,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled):hover .p-dropdown-label,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled):hover .p-multiselect-label,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled).p-state-focus .p-dropdown-label,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-state-disabled).p-state-focus .p-multiselect-label {
    font-size: 1.5rem;
    font-family: $font-light;
    font-weight: 300;
    color: $mine-shaft;
    height: auto;
    padding: 1rem;
  }
}