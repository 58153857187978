@import 'abstracts';

.cap-info-table.p-datatable .p-datatable-thead th {
  color: $mine-shaft;
  font-size: 0.938rem;
  position: relative;
  font-weight: 600;
  font-family: $open-sans-semibold;

  &:not(:last-of-type):hover {
    background-color: #e0e0e0;
    text-decoration: none;
  }

  &.p-sortable-column {
    padding-left: 1.5rem;
  }

  &:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    position: absolute;
    left: 8px;
    top: 14px;
    font-size: 0.8rem;
  }

  &[aria-sort='none']:before {
    content: '\f0dc';
  }

  &[aria-sort='ascending']:before {
    content: '\f0dd';
  }

  &[aria-sort='descending']:before {
    content: '\f0de';
  }

  cap-sort-icon {
    display: none;
  }

  span {
    color: $mine-shaft;
  }
}

cap-row-action {
  div.row-action {
    i:first-child {
      padding: 0.375rem;
      color: var(--cap-primary-color);
    }

    .row-action__items:before {
      right: 0.75rem;
    }

    .row-action__icon {
      margin: 0;

      i {
        vertical-align: middle;
        margin-right: 0.5rem;
        padding: 0;

        &:empty {
          display: none;
        }
      }
    }

    .row-action__info {
      .title {
        font-family: $source-sans-pro-regular;
        color: $abbey;
        line-height: $cap-row-action-items-icon-font-size;
        font-size: 1rem;
      }
    }
  }
}

.cap-info-table .p-datatable-loading-overlay {
  background-color: transparent;
}

.p-paginator-bottom {
  margin-bottom: 1rem;
}

.cap-info-table.p-datatable .p-paginator .p-paginator-prev,
.cap-info-table.p-datatable .p-paginator .p-paginator-next,
body .p-paginator .p-paginator-pages .p-paginator-page {
  width: 2.125rem;
  height: 2.125rem;
}

body .p-paginator .p-paginator-pages {
  border-left: 1px solid $gray;
}

.cap-info-table.p-datatable .p-datatable-tbody td {
  cursor: pointer;
  font-family: $open-sans-regular;
  font-size: 0.938rem;
  height: 2.875rem;
}

.cap-info-table.last-title-right .p-datatable-thead th:last-of-type {
  text-align: right;
  justify-content: end;
}

.cap-info-table.p-datatable .p-paginator {
  overflow: unset;
  display: flex;
  align-items: center;

  cap-validator {
    display: none;
  }
}

.cap-info-table.p-datatable .p-datatable-tbody tr.cap-table-row-selected > td:first-child {
  border-left: 1px solid $primary;
}

.cap-info-table.p-datatable .p-datatable-tbody tr.cap-table-row-selected > td {
  border-top: 1px solid $primary;
  border-bottom: 1px solid $primary;
}

.cap-info-table.p-datatable .p-datatable-tbody tr.cap-table-row-selected > td:last-child {
  border-right: 1px solid $primary;
}

.cap-cell-tooltip-container span {
  line-height: normal;
}
