@import 'abstracts';

p-confirmdialog {
  div.p-dialog.p-confirmdialog {
    border-radius: 5px;
    background-color: $white;

    div.p-dialog-titlebar,
    div.p-dialog-content,
    div.p-dialog-footer {
      border: none;
      border-radius: inherit;
      text-align: center;
    }

    div.p-dialog-titlebar {
      @include straightBottom();
      background: $white;
      color: $primary;
      font-family: $font-semi-bold;

      span {
        text-align: center;
        display: inline;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .p-dialog-titlebar-close span {
        font-size: 1rem;
      }
    }

    div.p-dialog-content {
      padding: 3.125rem 0;
      text-align: center;
      font-size: 1rem;
      line-height: normal;
    }
  }
}
