@import 'abstracts';

.tooltip-content {
  position: relative;
  display: inline;

  &:hover .tooltip-body,
  &:hover .tooltip-arrow {
    display: block;
  }

  i {
    color: var(--cap-primary-color);
  }
}

.text-tooltip {
  color: $fuscous-gray;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: normal;

  ul {
    padding: 0.2rem 0 0 1rem;

    li {
      list-style: outside;
    }
  }
}

body .p-tooltip {
  z-index: 1001;
  background: $white;
  font-size: 0.75rem;
  transform: translateY(-0.5rem);

  .p-tooltip-text {
    color: $dark-gray;
    padding: $small-gutter-width 0.938rem;
    font-size: 0.813rem;
    line-height: 0.938rem;
    border-radius: 2px;
  }

  .p-tooltip-arrow {
    color: $white;
    bottom: -3px;
    border-width: 6px 6px 0;
    left: calc(50% - 2px);
  }
}
