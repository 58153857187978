/* Base */
@import './base/reset';
@import './base/alignment';
@import './base/typography';

/* Vendor */
@import './vendor/bootstrap';
@import './vendor/prime';
@import './vendor/capturum-ui';
@import './vendor/font-awesone';

/* Components */
@import 'components/app-root';
@import 'components/buttons';
@import 'components/confirm-dialogs';
@import 'components/loading-spinner';
@import 'components/tooltip';
@import 'components/calendar';
@import 'components/inputs';
@import 'components/tables';
@import 'components/accordion';
@import 'components/radio-button';
@import 'components/dropdown';
@import 'components/textarea';
@import 'components/labels';
@import 'components/checkbox';
@import 'components/validators';
@import 'components/input-switch';
@import 'components/dialog';
@import 'components/notifications';
@import 'components/spinner';
