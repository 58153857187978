/* You can add global styles to this file, and also import other style files */
@import 'main.scss';

:root {
  --text-color-4: #fff;
}

* {
  outline: none !important; /* stylelint-disable-line declaration-no-important */
}


html {
  font-size: 54.25%;

  @include media-breakpoint-up(sm) {
    font-size: 62.5%;
  }
  @include media-breakpoint-up(md) {
    font-size: 68.75%;
  }
  @include media-breakpoint-up(lg) {
    font-size: 80%;
  }
  @include media-breakpoint-up(xl) { 
    font-size: 90%;
  }
}

body {
  min-height: 100%;
  background-color: $body-color;

  @include media-breakpoint-down(md) {
    overflow-x: hidden;
  }
}
