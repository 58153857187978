@import 'abstracts';

body .p-field-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  label {
    font-weight: 500;
    margin-left: 0.6rem;
    color: $fuscous-gray;
    font-family: $font-regular;
    font-size: 1rem;
    cursor: pointer;
  }
}

body .p-radiobutton-label {
  font-weight: inherit;
}

body .p-radiobutton.p-radiobutton-checked .p-radiobutton-box {
  background-color: $white;
}

body .p-radiobutton.p-radiobutton-checked .p-radiobutton-box .p-radiobutton-icon {
  background-color: $primary;
}

body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 1.125rem;
  height: 1.125rem;
  left: 0;
  right: 0;
  top: 0.063rem;
  margin: auto;
}

body .p-radiobutton .p-radiobutton-box {
  width: 1.375rem;
  height: 1.375rem;
}

.p-field-radiobutton .p-field-checkbox label {
  margin-bottom: 0;
}
