/* Grid */
$grid-gutter-width: 1.875rem;
$middle-gutter-width: $grid-gutter-width / 2; /* stylelint-disable-line */
$small-gutter-width: $grid-gutter-width / 3; /* stylelint-disable-line */
$tiny-gutter-width: $grid-gutter-width / 4; /* stylelint-disable-line */

/* General colors */
$primary: #145996;
$secondary: #c91f55;
$white: #fff;
$black: #000;
$green: #008839;
$gold: #f5a623;
$blue: #155995;
$dark-blue: #1a2c68;
$light-gray: #b7b7b7;
$very-light-gray: #f0f0f0;
$dark-gray: #5a5a5a;
$tuatara: #3c3c3b;
$albaster: #f9f9f9;
$secondary-bg: #f4f5f6;
$component-bg: #fafafa;
$light-green: #43a047;
$crimson: #c51f53;
$red: #e02020;
$red-range: #ff3b30;
$silver: #bebdbd;
$silver-light: #ccc;
$gray: #ddd;
$very-light-green: #82ad11;
$toxic: #68e76f;
$tundora: #4c4c4c;
$mine-shaft: #333;
$abbey: #4f5152;
$mid-gray: #5f6062;
$fuscous-gray: #4e4e4c;
$transparent-gray: #33333380;
$lipstick: #c50055;
$alto: #d9d9d9;

/* Filter colors */
$filter-dark-gray: invert(82%) sepia(9%) saturate(11%) hue-rotate(137deg) brightness(40%) contrast(87%);

/* Overlay colors */
$overlay-dark: rgba(0, 0, 0, 0.7);
$overlay-light: rgba(255, 255, 255, 0.6);
$overlay-gray: rgba(247, 247, 247, 0.5);

/* Fonts */
$font-semi-bold: opensans semibold;

/* Borders */
$secondary-border-color: #9b9b9b;
$light-gray-border-color: rgba(0, 0, 0, 0.09);
$tooth-border: rgba(151, 151, 151, 0.24);

$border-radius: 1.25rem;
$default-border-radius: 3px;

/* Box-Shadows */
$shadow-gray: rgba(190, 189, 189, 0.5);
$tooltip-shadow: rgba(0, 0, 0, 0.12);

/* Spacing */
$mobile-spacing: 15px;
$dashboard-page-padding: 40px;

/* General */
$body-color: #f7f7f7;
$white: #fff;
$root-font-size: 16;

$cap-input-height: 33px;

/* Sidebar */
$sidebar-header: linear-gradient(90deg, #145996, #1a2c68);
$sidebar-width: 485px;

/* Fonts */
$font-light: 'DINOT Light';
$font-regular: 'DINOT Regular';
$font-medium: 'DINOT Medium';
$font-bold: 'DINOT Bold';
$open-sans-light: 'OpenSans-Light';
$open-sans-regular: 'OpenSans-Regular';
$open-sans-semibold: 'OpenSans-Semibold';
$open-sans-bold: 'OpenSans-Bold';
$source-sans-pro-light: 'OpenSansPro-Light';
$source-sans-pro-regular: 'OpenSansPro-Regular';
$source-sans-pro-semibold: 'OpenSansPro-Semibold';
$source-sans-pro-bold: 'OpenSansPro-Bold';

/* Layers - Place all z-index related variables in this section */
$accordion-layer: 100;
$panel-layer: 999;
$sidebar-overlay: 1000;
$sidebar-layer: 1001;
$top-layer: 99000;

/* Overlay background */
$overlay-bg-color: #fff;
$overlay-bg-opacity: 0.7;

/* Resolutions */
$middle-resolution: 1700px;
$small-resolution: 1400px;
