@import 'abstracts';

cap-input.ng-invalid input.ng-touched {
  border: 1px solid $red;
}

body .validator {
  margin-top: 0.4rem !important; /* stylelint-disable-line declaration-no-important */

  &:not(.show) {
    .error-text {
      display: none;
    }
  }

  .error-text {
    font-family: $source-sans-pro-semibold;
    color: $red-range;
    font-size: 0.75rem;

    i {
      display: none;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
    }
  }
}

cap-textarea cap-validator .validator {
  visibility: visible !important; /* stylelint-disable-line declaration-no-important */

  .error-text {
    display: block !important; /* stylelint-disable-line declaration-no-important */
  }
}

.hide-validator {
  .validator,
  cap-validator {
    display: none;
  }
}

cap-validator {
  .validator {
    min-height: 0.6rem !important; /* stylelint-disable-line declaration-no-important */
  }
}
