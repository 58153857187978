@import 'abstracts';

.prime-notification-bar {
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
  background: $body-color;

  .head, hr {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  .scroll {
    overflow: auto;
    margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    padding: $small-gutter-width 2.813rem;
  }

  strong:not(.title) {
    font-family: $font-bold;
    font-size: 1.125rem;
    color: $tuatara;
    margin-bottom: 1.25rem;
    padding-top: 0.813rem;
    display: block;
  }

  .notification-item-card {
    display: flex;
    background: $white;
    height: 6.5rem;
    margin-bottom: 0.813rem;
    border-radius: 3px;
    border: 1px solid $gray;

    .status-icon {
      width: 5.188rem;
      @include flexCentering();

      i {
        font-size: 1.313rem;
        color: white;
        width: 3.188rem;
        height: 3.188rem;
        border-radius: 50%;
        position: relative;
        @include flexCentering();

        &:after {
          content: '';
          position: absolute;
          width: 3.063rem;
          height: 3.063rem;
          border-radius: 50%;
          border: 2px solid $white;
        }
      }

      .fa-bell {
        background: $gold;
      }

      .fa-flag {
        background: $very-light-green;
      }

      .fa-exclamation-triangle {
        background: $lipstick;
      }
    }

    .notification {
      width: calc(100% - 5.188rem);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: $small-gutter-width;

      .title {
        font-family: $font-medium;
        font-size: 1rem;
        color: $tuatara;
      }

      .sub-title {
        color: $dark-blue;
        font-size: 0.563rem;
        font-family: $open-sans-regular;
      }

      .body {
        color: $mid-gray;
        font-family: $open-sans-regular;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
  }
}
