@import 'abstracts';

body .p-inputgroup input.p-inputtext.cap-rounded,
body .p-inputgroup input.p-inputtext:hover.cap-rounded,
body .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error).cap-rounded,
body .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error).cap-rounded,
body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn,
body .cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn:hover {
  font-size: 0.875rem;
  border-color: $cap-input-border-color;

  @include media-breakpoint-down(md) {
    border-radius: 3px;
    font-size: 1.5rem;
  }
}

body cap-input.has-icon .p-inputgroup input.p-inputtext,
body cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error),
body cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error),
body cap-multi-select.has-icon .p-multiselect .p-multiselect-label {
  padding-left: 2.7rem;

  @include media-breakpoint-down(md) {
    padding-left: 3.5rem;
  }
}

body .filter-icon {
  left: 2rem;
  top: 8px;
  display: block;


  @include media-breakpoint-down(md) {
    display: block;
    top: 1rem;
  }

  i {
    opacity: 0.8;
    font-size: 1rem;

    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
  }
}

body .p-inputgroup input.p-inputtext,
body .p-inputgroup input.p-inputtext:hover,
body .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error),
body .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error) {
  padding-left: 1rem;
  font-size: 1rem;
}

app-group-input {
  h3.label {
    font-family: $font-bold;
    color: $tuatara;
  }

  .group-heading {
    margin-bottom: 1.62rem;
    min-height: 1.625rem;

    & > * {
      display: inline;

      &.tooltip-content {
        float: right;
        margin-top: 0.35rem;
      }
    }
  }
}

body cap-input.has-icon .p-inputgroup input.p-inputtext,
body cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error),
body cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error),
body cap-multi-select.has-icon .p-multiselect .p-multiselect-label {
 height: auto;
}


@include media-breakpoint-down(md) {
  .form-element {
    margin-bottom: 0.8rem;
  }

  body .p-inputgroup input.p-inputtext,
  body .p-inputgroup input.p-inputtext:hover,
  body .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error),
  body .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error),
  .cap-dropdown.p-dropdown .p-dropdown-label .selected-label,
  .cap-dropdown.p-dropdown .p-multiselect-label .selected-label,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-dropdown-label .selected-label,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-multiselect-label .selected-label,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-dropdown-label .selected-label,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-multiselect-label .selected-label,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-dropdown-label .selected-label,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-multiselect-label .selected-label, 
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-dropdown-label .selected-label,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-multiselect-label .selected-label,
  .cap-multi-select.p-multiselect .p-dropdown-label .selected-label,
  .cap-multi-select.p-multiselect .p-multiselect-label .selected-label, 
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-dropdown-label .selected-label,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-multiselect-label .selected-label,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-dropdown-label .selected-label,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-multiselect-label .selected-label,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-dropdown-label .selected-label,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-multiselect-label .selected-label,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-dropdown-label .selected-label,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-multiselect-label .selected-label {
    font-size: 1.5rem;
    font-family: $font-light;
    font-weight: 300;
    color: $mine-shaft;
  }
}
