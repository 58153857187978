@import 'abstracts';

$border-radius: 3px;

p-checkbox {
  display: flex;
  align-items: flex-start;
}

body .p-chkbox {
  margin-top: 0.188rem;
}

body .p-checkbox-label {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  font-family: $font-regular;
  cursor: pointer;
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-family: 'PrimeIcons' !important;
}

.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight.p-focus,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover,
body .p-checkbox .p-checkbox-box {
  border: 1px solid $gray;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.5rem;
  border-radius: $border-radius;
}

body .p-checkbox .p-checkbox-box.p-checkbox-checked,
body .p-checkbox .p-checkbox-box.p-checkbox-checked.p-focus,
body .p-checkbox .p-checkbox-box.p-checkbox-checked:not(.p-disabled):hover,
body .p-checkbox .p-checkbox-box.p-checkbox-checked.p-focus:not(.p-disabled):hover {
  border: 1px solid $primary;
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon,
body .p-checkbox .p-checkbox-box.p-focus .p-checkbox-icon,
body .p-checkbox .p-checkbox-box:not(.p-disabled):hover .p-checkbox-icon,
body .p-checkbox .p-checkbox-box.p-focus:not(.p-disabled):hover .p-checkbox-icon {
  @include flexCentering;
  height: 100%;
  margin: 0;
}

.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon:before,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight.p-focus .p-checkbox-icon:before,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover .p-checkbox-icon:before,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover .p-checkbox-icon:before,
body .p-checkbox .p-checkbox-box .p-checkbox-icon:before,
body .p-checkbox .p-checkbox-box.p-focus .p-checkbox-icon:before,
body .p-checkbox .p-checkbox-box:not(.p-disabled):hover .p-checkbox-icon:before,
body .p-checkbox .p-checkbox-box.p-focus:not(.p-disabled):hover .p-checkbox-icon:before {
  background: $primary;
  color: $primary;
  width: 1rem;
  height: 1rem;
  border-radius: $border-radius;
}

.small-description {
  padding-left: 2rem;
  font-size: $small-gutter-width;
  font-family: Helvetica;
}

app-workpieces,
app-tooth-cross {
  .disabled formly-field cap-checkbox p-checkbox {
    opacity: 0.5;
    pointer-events: none;
  }
}
