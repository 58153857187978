@import 'abstracts';
@import 'emendis.theme';
@import 'styles';

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
  background: $green;
}

.p-button.cap-button {
  padding: 0.688rem 1.583rem;
  border-radius: 5px;
  font-size: 1rem;
}

body .p-button-label-icon-left .p-button-label {
  padding-left: 1.7rem;
  padding-right: 0;
  font-family: $font-medium;
}

body .p-button-label-icon-left .p-button-icon-left {
  left: 1.5rem;
}

body .p-button-label-icon-right .p-button-label {
  padding-right: 1.7rem;
  padding-left: 0.5rem;
}

body .p-button.p-button-label-icon-right .p-button-icon-right.p-button-icon {
  right: 1rem;
  padding-right: 0;
  width: unset;
  line-height: 1rem;
  margin-top: -7px;
}

:root {
  --cap-primary-color: #145996;
  --cap-secondary-color: #c91f55;
  --cap-lightGray-color: #c91f55;
  --cap-text-color1: #145996;
  --cap-white-color: #fff;
}
