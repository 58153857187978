@import 'abstracts';

body .cap-loader {
  .p-progress-spinner-circle {
    animation: dental-progress-spinner-color 6s ease-in-out infinite;
  }

  span.cap-loader-text {
    color: $secondary;
  }

  @keyframes dental-progress-spinner-color {
    100%,
    0% {
      stroke: $secondary;
    }
  }
}

.spinner-wrapper {
  display: flex;
}

.fa-spinner-third {
  border-radius: 50%;
  background: $primary;
  color: $white;
  margin-left: auto;
  width: 1.563rem !important;
  height: 1.563rem !important;
  left: 0.938rem !important;
  top: 1.063rem !important;
  font-size: 1.125rem;
  padding: 3px;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(-360deg);
  }
}
