@import 'abstracts';

@font-face {
  font-family: $font-light;
  font-style: normal;
  font-weight: 300;
  src: url(getFontPath('DINOT-Light.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $font-regular;
  font-style: normal;
  font-weight: normal;
  src: url(getFontPath('DINOT-Regular.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $font-medium;
  font-style: normal;
  font-weight: 600;
  src: url(getFontPath('DINOT-Medium.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $font-bold;
  font-style: normal;
  font-weight: 900;
  src: url(getFontPath('DINOT-Bold.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $open-sans-light;
  font-style: normal;
  font-weight: 300;
  src: url(getFontPath('OpenSans-Light.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $open-sans-regular;
  font-style: normal;
  font-weight: normal;
  src: url(getFontPath('OpenSans-Regular.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $open-sans-semibold;
  font-style: normal;
  font-weight: 600;
  src: url(getFontPath('OpenSans-SemiBold.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $open-sans-bold;
  font-style: normal;
  font-weight: bold;
  src: url(getFontPath('OpenSans-Bold.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $source-sans-pro-light;
  font-style: normal;
  font-weight: 300;
  src: url(getFontPath('SourceSansPro-Light.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $source-sans-pro-regular;
  font-style: normal;
  font-weight: normal;
  src: url(getFontPath('SourceSansPro-Regular.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $source-sans-pro-semibold;
  font-style: normal;
  font-weight: 600;
  src: url(getFontPath('SourceSansPro-SemiBold.ttf')); /* stylelint-disable-line function-url-quotes */
}

@font-face {
  font-family: $source-sans-pro-bold;
  font-style: normal;
  font-weight: bold;
  src: url(getFontPath('SourceSansPro-Bold.ttf')); /* stylelint-disable-line function-url-quotes */
}

h1 {
  font-family: $font-bold;
  font-size: 1.75rem;
  line-height: 2.375rem;
  font-weight: bold;
}

h2 {
  font-family: $font-bold;
  font-size: 1.375rem;
  line-height: 1.75rem;

  @include media-breakpoint-down(md) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

h3 {
  font-family: $font-medium;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

h4 {
  font-family: $font-medium;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

p {
  font-family: $font-regular;
  font-size: 1rem;
  line-height: 1.625rem;

  @include media-breakpoint-down(md) {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

* {
  font-family: $font-regular;
}

a {
  text-decoration: none;
  transition: opacity ease 200ms;

  &:hover {
    opacity: 0.8;
  }
}

.small-text {
  font-size: 0.813rem;
}

.text-primary {
  color: $primary !important; /* stylelint-disable-line declaration-no-important */
}

.text-important {
  color: $red !important; /* stylelint-disable-line declaration-no-important */
}

@for $font-size from 10 through 20 {
  .fs-#{$font-size} {
    font-size: 0 + $font-size !important; /* stylelint-disable-line declaration-no-important */
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.icon-primary {
  font-size: 1.438rem;
}

.font-weight-bold {
  font-family: $font-bold;
  font-weight: bold;
}

.font-weight-light {
  font-family: $font-light;
  font-weight: 300;
}
