@import 'abstracts';

button {
  cursor: pointer;
}

@mixin text-decoration-none {
  text-decoration: none !important; /* stylelint-disable-line declaration-no-important */
}

.button-group {
  button {
    margin-left: 1rem;
  }
}

.p-button .p-button-label {
  padding: 0.25rem 1rem;
}

body .p-button.p-button-label-icon-left,
body .p-button.p-button-label-icon-right,
.p-button {
  .p-button-icon {
    position: absolute;
    top: 50%;
    margin-top: -5px;
    height: 1rem;
    width: 1rem;

    @include media-breakpoint-down(md) {
      left: 0.75rem;
    }
  }

  .fa-spinner-third {
    background: transparent;
  }

  .p-button-label {
    padding-top: 0;
    padding-bottom: 0;
    line-height: normal;
  }
}

body cap-button button.cap-button {
  height: 2.813rem;
  transition: all 0.3s;

  &:focus {
    border-color: transparent;
  }

  &.primary {
    background-color: var(--cap-primary-color);
    border-color: var(--cap-primary-color);
  }

  &.secondary {
    background-color: var(--cap-secondary-color);
  }

  &.remove {
    background-color: $white;
    color: $red;
    border-color: $red;
  }
}

.p-button.cap-button:disabled {
  pointer-events: none;
}

cap-button.disabled {
  background-color: var(--cap-lightGray-color);
  pointer-events: none;
  border-radius: $border-radius;

  .cap-button {
    border-color: transparent;
    background: var(--cap-lightGray-color);
  }
}

body cap-button .icon-btn-transparent {
  background-color: transparent;
  border-color: transparent;
  color: var(--cap-primary-color);
}

body cap-button .icon-btn-primary {
  background-color: var(--cap-primary-color);
  border-color: transparent;
  color: var(--text-color-4);
}

body .p-button.cap-button.btn-rounded {
  border-radius: 50px;
}

body .cap-select-button.p-selectbutton .p-button:not(.p-state-disabled):not(.p-state-active):hover,
body .cap-select-button.p-selectbutton .p-button {
  padding: 0.563rem 0;
  min-width: 8.5rem;
  font-size: 0.938rem;
  font-family: $font-medium;

  &.p-button {
    border-right: none;
  }

  &:last-child {
    border-right: 1px solid;
  }

  @include media-breakpoint-down(lg) {
    width: unset;
    padding: 0.313rem 1.25rem;
  }
}

body .cap-button.primary:not(:disabled):hover,
body .cap-button.primary:enabled:hover {
  @include text-decoration-none;
  background-color: darken($primary, 5%);

  &:not(:disabled):hover span:not(.p-button-icon),
  &:enabled:hover span:not(.p-button-icon) {
    @include text-decoration-none;
  }
}

body .cap-button.special {
  color: $white !important; /* stylelint-disable-line declaration-no-important */
  border-color: $secondary !important; /* stylelint-disable-line declaration-no-important */
  background-color: $secondary !important; /* stylelint-disable-line declaration-no-important */
}

body .cap-button.short {
  height: 2.27rem;

  &.p-button.cap-button {
    font-size: 0.95rem;

    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
  }

  &:not(.p-button-label-icon-left) {
    .p-button-label {
      padding: 0;
    }
  }
}

body .cap-button.secondary:not(:disabled):hover,
body .cap-button.secondary:enabled:hover {
  @include text-decoration-none;
  background-color: darken($secondary-bg, 5%);

  &:not(:disabled):hover span:not(.p-button-icon),
  &:enabled:hover span:not(.p-button-icon) {
    @include text-decoration-none;
  }

  &.special {
    background-color: darken($secondary, 5%);
  }
}

body .cap-button.remove:not(:disabled):hover,
body .cap-button.remove:enabled:hover {
  @include text-decoration-none;
  background-color: darken($secondary-bg, 5%);

  &:not(:disabled):hover span:not(.p-button-icon),
  &:enabled:hover span:not(.p-button-icon) {
    @include text-decoration-none;
  }

  &.special {
    background-color: darken($secondary, 5%);
  }
}

body .cap-button.success:not(:disabled):hover,
body .cap-button.success:enabled:hover {
  @include text-decoration-none;
  background-color: darken($very-light-green, 5%);

  &:not(:disabled):hover span:not(.p-button-icon),
  &:enabled:hover span:not(.p-button-icon) {
    @include text-decoration-none;
  }
}

body .cap-select-button.p-selectbutton .p-button:not(.p-state-disabled):not(.p-highlight):hover:not(.p-state-disabled):not(.p-highlight):hover,
body .cap-select-button.p-selectbutton .p-button:not(.p-state-disabled):not(.p-highlight):hover {
  @include text-decoration-none;
  background-color: darken($white, 5%);

  &:not(:disabled):hover span:not(.p-button-icon),
  &:enabled:hover span:not(.p-button-icon) {
    @include text-decoration-none;
  }
}

body .cap-button.success:not(:disabled) {
  background-color: $very-light-green;
  color: $white;
  border: none;
}


@include media-breakpoint-down(md) {
  .p-button.cap-button {
    font-size: 1.6rem;
    font-family: $font-bold;
    padding: 0.5rem 1rem;
    height: auto !important; /* stylelint-disable-line declaration-no-important */
  }
}
